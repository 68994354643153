<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/store/components/PhoneInput.vue
 * @Author: 张兴业
 * @Date: 2020-12-08 23:36:00
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-13 16:34:52
-->

<template>
  <div class="phone_input">
    <el-form-item label-width="100px" prop="phone">
      <div slot="label" class="prefix_menu">
        <div v-cloak v-clickoutside="outsideClose" class="dropmain">
          <div @click="show = !show" class="dropbutton">
            <span>{{ prefix }}</span>
            <!-- <van-icon name="arrow-down" class="prefix_icon" /> -->
            <i id="prefix_icon" class="el-icon-arrow-down" />
          </div>
          <div class="dropdown" v-show="show">
            <div
              v-for="(item, index) in options"
              :key="index"
              class="dropdown_item"
              @click="changePrefix(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <el-input
        v-model="phone"
        name="phone"
        placeholder="请输入手机号"
        class="phone"
        @input="change"
      />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      phone: this.value,
      prefix: "中国+86",
      show: false,
      options: [
        {
          title: "中国+86",
          value: "+86"
        },
        {
          title: "澳大利亚+61",
          value: "+61"
        },
        {
          title: "新西兰+64",
          value: "+64"
        }
      ]
    };
  },
  directives: {
    clickoutside: {
      bind(el, binding) {
        function documentHandler(e) {
          if (el.contains(e.target)) {
            return false;
          }
          if (binding.expression) {
            binding.value(e);
          }
        }

        function KeyUp(e) {
          if (e.keyCode == 27) {
            if (binding.expression) {
              binding.value(e);
            }
          }
        }
        el.__vueClickOutSize__ = documentHandler;
        el.__vueKeyup__ = KeyUp;

        document.addEventListener("keyup", KeyUp);
        document.addEventListener("click", documentHandler);
      },
      unbind(el) {
        document.removeEventListener("click", el.__vueClickOutSize__);
        delete el.__vueClickOutSize__;

        document.removeEventListener("keyup", el.__vueKeyup__);
        delete el.__vueKeyup__;
      }
    }
  },
  methods: {
    outsideClose() {
      this.show = false;
    },
    changePrefix(item) {
      this.prefix = item.title;
      this.show = false;
      this.$emit("prefixChange", item.value);
    },
    change(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="less" scoped>
.phone_input {
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: 32px;
    border-radius: 0;
    width: calc(100% - 32px);
  }
  ::v-deep .el-form-item__error {
    margin-left: 32px;
  }
}

.prefix_menu {
  margin: auto 0;

  [v-cloak] {
    display: none;
  }

  .dropmain {
    width: 100px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .dropbutton {
    display: block;
    width: 100px;
    height: 39px;
    line-height: 39px;
    color: black;
    // background-color: #39f;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    // border-radius: 4px;
    cursor: pointer;
    outline: none;
    user-select: none;

    span {
      vertical-align: middle;
    }

    #prefix_icon {
      vertical-align: middle;
      margin-left: 6px;
    }
  }

  .dropdown {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100px;
    font-size: 12px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    margin-top: 2px;
    z-index: 1999;
  }

  .dropdown_item {
    height: 32px;
    line-height: 32px;
    // padding-left: 10px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
}
</style>
